import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import NotFoundPage from "../../layouts/404";

const NotFoundPageEn = () => {
  return (
    <Layout 
    lang='en-CA' 
    switchLink='/404/'
    slider={false} >
      <Seo title="Page not found" />
      <NotFoundPage lang='en-ca'/>
    </Layout>
  )
}

export default NotFoundPageEn
